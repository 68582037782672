




























































import { Component, Vue, Mixins, Ref, Prop, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import TimePickerBase from '@/components/atoms/TimePickerBase.vue'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import StudentInformationTimeline from '@/components/organisms/v3/StudentInformationTimeline.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import moment from 'moment'
import { TimeLineType } from '@/types/teacher/homeworkSetting'
import _ from 'lodash'

@Component({
  components: {
    ButtonBase,
    PopupBase,
    DatePickerBase,
    TimePickerBase,
    StudentInformationTimeline,
    SelectBase,
    DatePickerIcon,
    CheckboxSquare,
  },
})
export default class HistoryLearning extends Mixins(LoadClassOptionsApi) {
  @Prop()
  private isOpen!: boolean
  @Prop()
  private onClose!: () => void
  @Prop()
  private onSubmit!: (historis: TimeLineType[]) => void

  private observer!: IntersectionObserver
  @Ref() observeElement!: HTMLElement

  private defaultOptionDatas = [{ value: 0, text: '未実施' }]
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private timelineDatas: TimeLineType[] = []
  private timelineDatasOrigin: TimeLineType[] = []
  private lastData: any = {}
  private attributeSelectedData: string | null = null
  private attributeOptionDatas: { text: string; value: string | null }[] = []
  private today = this.getTimeJapan()

  private getTimeJapan() {
    const now = new Date()
    const options = { timeZone: 'Asia/Tokyo' }
    const japanLocalTime = now.toLocaleString('ja-JP', options)
    const japanLocalDateTime = new Date(japanLocalTime)
    return japanLocalDateTime
  }

  private defaultDateTime = {
    startDate: moment(this.today).format('YYYY/MM/DD'),
    endDate: moment(this.today).format('YYYY/MM/DD'),
    startTime: '00:00',
    endTime: moment(this.today).format('HH:mm'),
  }
  private startAndEndTime = this.defaultDateTime

  private get colortype(): string {
    const permitted = this.timelineDatas.some((item) => item.checked)
    return permitted ? 'blue' : 'pointer-events-none'
  }

  @Watch('isOpen')
  private watchOpen() {
    if (this.isOpen) {
      this.loadDatasTimeline(true)
    } else {
      this.lastData = {}
    }
  }

  private handleClose() {
    this.timelineDatas = []
    this.onClose()
  }

  private handleSubmit() {
    this.onSubmit(this.timelineDatas.filter((item) => item.checked))
    this.onClose()
  }

  private onSelectDateStart(params: { date: moment.Moment }) {
    if (!params.date) {
      alert('日付が選択されていません')
      return
    }
    this.startAndEndTime.startDate = params.date.format('YYYY/MM/DD')
  }
  private onSelectDateEnd(params: { date: moment.Moment }) {
    if (!params.date) {
      alert('日付が選択されていません')
      return
    }
    this.startAndEndTime.endDate = params.date.format('YYYY/MM/DD')
  }

  private handleSearch() {
    this.timelineDatas = []
    this.lastData = {}
    this.loadDatasTimeline(true)
  }

  private async loadDatasTimeline(isSearch?: boolean) {
    // 期間開始に最終レコードの学習開始日時を設定
    const startedAtGteq = this.startAndEndTime.startDate.toString() + ' ' + this.startAndEndTime.startTime.toString()
    const startedAtLteq = this.startAndEndTime.endDate.toString() + ' ' + this.startAndEndTime.endTime.toString()
    const params: any = {
      startedAtGteq,
      classModes: ['CUSTOM', 'AI'],
      classCategories: ['RK', 'EN'],
      isHomework: true,
    }
    if (this.startAndEndTime.endDate) {
      params.startedAtLteq = startedAtLteq
    }
    if (this.attributeSelectedData) {
      params.attribute = this.attributeSelectedData
    }

    // TODO: 生徒アイコンは未定
    await Vue.prototype.$http.httpWithToken
      .post('/v3/timelines', { branchId: this.branchId, q: params, lastData: isSearch ? {} : this.lastData })
      .then((res: any) => {
        const add_timelines: [] = res.data.timelines.map((timeline: any) => {
          return {
            id: timeline.studentCode,
            name: timeline.nickname,
            icon: '',
            school: timeline.schoolName,
            grade: timeline.gradeName,
            date: timeline.studyDate,
            time: timeline.studyTime,
            type: timeline.studyType,
            item_text: timeline.lessonName,
            material: timeline.lessonType,
            study_time: timeline.duration,
            study_count: timeline.completeCount,
            result: { all: timeline.questionCount, correct: timeline.correctCount },
            learningFlag: timeline.learningFlag,
            resultDrillId: timeline.resultDrillId,
            resultDrillVersion: timeline.resultDrillVersion,
            checked: true,
            classModeId: timeline.classModeId,
            curriculumSUnitId: timeline.curriculumSUnitId,
            subjectId: timeline.subjectId,
            userId: timeline.userId,
            isModeDefault: timeline.isModeDefault,
          } as TimeLineType
        })
        isSearch ? (this.timelineDatas = add_timelines) : this.timelineDatas.push(...add_timelines)
        this.lastData = res.data.meta.lastData
        this.observer.observe(this.observeElement)
        // 最終行の場合、監視終了
        if (!add_timelines.length) this.observer?.disconnect()
      })
  }

  private async loadOptions() {
    const emptyOption = [{ text: '未選択', value: null }]
    const attributeOptions = await this.getAttributes()
    this.attributeOptionDatas = emptyOption.concat(attributeOptions)
  }

  private async getAttributes(): Promise<any[]> {
    // グループプルダウンの情報設定
    let attributeOptions: any[] = []
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        attributeOptions = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id.toString(),
          }
        })
      })
    return attributeOptions
  }

  private async mounted(): Promise<void> {
    await this.loadOptions()
    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry && entry.isIntersecting) {
        if (Object.keys(this.lastData).length) {
          // 追加取得
          this.loadDatasTimeline()
        }
      }
    })
    this.observer.observe(this.observeElement)
  }
}
