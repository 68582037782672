















































import { Component, Vue } from 'vue-property-decorator'
import SettingItem from '@/components/molecules/v3/SettingItem.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LearningEndCondition from '@/components/organisms/v3/mode/LearningEndCondition.vue'
import HistoryLearning from '@/components/organisms/v3/HistoryLearning.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import { ConditionExit, Level, Subject } from '@/types/teacher/mode'
import { HomeworkType, TimeLineType } from '@/types/teacher/homeworkSetting'
import { convertDataHomeworkSubmit } from '@/utils/teacher/homework'
import Suspense from '@/components/molecules/v3/Suspense.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    SettingItem,
    CheckboxSquare,
    ButtonBase,
    TitleTextBase,
    TitleBase,
    LearningEndCondition,
    HistoryLearning,
    RadioToggle,
    Suspense,
  },
})
export default class HomeWorkSetting extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: '宿題作成', active: true },
  ]

  private DEPTH_OF_TRACE = [
    { name: 'OFF', value: Level.OFF },
    { name: '1単元分', value: Level.ONE },
    { name: '2単元分', value: Level.TWO },
    { name: '3単元分', value: Level.THREE },
    { name: '際限なし', value: Level.UNLIMITED },
  ]

  private isLoading = true

  private homework: HomeworkType = {
    depth_of_trace: Level.OFF,
    learning_end_condition: {
      type: 1,
      data: [],
    },
    homework_setting_item: {
      data: [],
    },
    histories: [],
  }
  private subjects: Subject[] = []
  private isEdit = false
  private showModalEndCondition = false
  private showModalTimeline = false
  private isCreate = true

  private awaitCallApi = false

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private get historyChecked() {
    return this.homework.histories?.some((history: any) => history?.checked)
  }

  private async handleSubmit() {
    const dataSubmit = convertDataHomeworkSubmit(this.homework)
    if (this.isCreate && !dataSubmit.homework_setting_item.data.length) {
      alert('宿題を選んでください')
      return
    }
    let result = false
    this.awaitCallApi = true
    if (this.isCreate) {
      result = await this.createHomework(dataSubmit)
    } else {
      result = await this.updateHomework(dataSubmit)
    }
    this.awaitCallApi = false
    if (result) {
      alert('設定内容が保存されました。')
      await this.getHomework()
    } else {
      alert('セットアップに失敗しました。後でもう一度お試しください')
    }
  }

  private async createHomework(dataSubmit: any) {
    try {
      await Vue.prototype.$http.httpWithToken.post('/v3/homework_settings', { ...dataSubmit, branchId: this.branchId })
      return true
    } catch {
      return false
    }
  }

  private async updateHomework(dataSubmit: any) {
    try {
      await Vue.prototype.$http.httpWithToken.patch('/v3/homework_settings', { ...dataSubmit, branchId: this.branchId })
      return true
    } catch {
      return false
    }
  }

  private async getHomework() {
    try {
      Vue.prototype.$loading.start()
      const { data } = await Vue.prototype.$http.httpWithToken.get('/v3/homework_settings')
      Vue.prototype.$loading.complete()
      if (data.homework_setting_item.data.length > 0) {
        this.isCreate = false
        this.homework.depth_of_trace = data.depth_of_trace
        this.homework.learning_end_condition.data = data.learning_end_condition.data
        if (this.homework.homework_setting_item) {
          this.homework.homework_setting_item.data = data.homework_setting_item.data
        }
      }
    } catch {
      return
    }
  }

  private async fetchListSubject() {
    const response = await Vue.prototype.$http.httpWithToken.get('/subjects')
    this.subjects = response.data.map((subject: any, index: number) => {
      let checked = false
      if (index === 0) {
        checked = true
      }
      return { subjectId: subject.id, name: subject.name, checked, code: subject.code }
    })
  }

  private handleConditionEndLearning(type: number, conditionEnd: ConditionExit[], isChange = false) {
    this.homework.learning_end_condition = { data: conditionEnd }
    this.isEdit = isChange
  }

  private handleChangeHistory(histories: TimeLineType[]) {
    this.homework.histories = [...histories]
  }
  private async mounted() {
    await this.getHomework()
    await this.fetchListSubject()
    this.isLoading = false
  }
}
