import { HomeworkItem, HomeworkType } from '@/types/teacher/homeworkSetting'
import { LearnEndConditionType } from './../../types/teacher/homeworkSetting'

export const convertDataHomeworkSubmit = (homework: HomeworkType) => {
  const dataSubmit: any = {}
  dataSubmit.homework_setting_item = {}
  dataSubmit.learning_end_condition = {}
  dataSubmit.depth_of_trace = homework.depth_of_trace
  dataSubmit.homework_setting_item.data = homework.histories?.map((item) => {
    return {
      class_mode_id: item.classModeId,
      curriculum_s_unit_id: item.curriculumSUnitId,
      subject_id: item.subjectId,
      user_id: item.userId,
    } as HomeworkItem
  })
  dataSubmit.learning_end_condition.data = homework.learning_end_condition.data.map((item) => {
    return {
      restriction_id: item.restrictionId,
      subject_id: item.subjectId,
      limit_threshold: item.limitThreshold,
    } as LearnEndConditionType
  })

  return dataSubmit
}
